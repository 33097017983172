body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}
body {
  font-family: 'Montserrat', sans-serif;
}
button,
input {
  font-family: 'Montserrat', sans-serif;
}
/* FOR DEBUGGING 
   MAX WIDTH SAME AS THE ONE IN BLOGPOST class */
/* table {
  max-width: 2000px;
}

table,
.td {
  border: 1px solid red;
  max-width: 200px;
}
div {
  border: 1px solid blue;
  word-wrap: break-word;
} */
img {
  width: 100%;
  height: auto;
}
table,
.tr {
  max-width: 2000px;
}
/* END OF TABLE CSS style */
.container {
  max-width: 1140px;
  width: 95%;
  margin: 0 auto;
  padding: 1rem 0;
}
/* BlogPost */
.blog-wrap {
  max-width: 700px;
  margin: 0 auto;
}
.blog-goBack {
  text-decoration: none;
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
  margin-bottom: 2rem;
  display: block;
}
.blog-wrap header {
  text-align: center;
}
.blog-date {
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
}
.blog-wrap img {
  width: 100%;
}
.blog-subCategory {
  display: flex;
  justify-content: center;
}
.blog-subCategory > div {
  margin: 1rem;
}
.blog-content p{
  margin-top: 1.5rem;
}
.blog-content h1, .blog-content h2, .blog-content h3, .blog-content h4, .blog-content h5, .blog-content h6 {
  margin-top: 1.5rem;
}

.blog-content img {
  margin: 10px 0;
}
.btn{
  z-index: 5;
}
/* Header */
.home-header {
  text-align: center;
}
.home-header h2 {
  color: #0080ff;
  font-size: 2rem;
}
.home-header h1 {
  font-size: 3rem;
  color: #0f52ba;
  margin-bottom: 1rem;
}
.home-header h1 span {
  color: #b0c4de;
}
.home-header p {
  color: #a9a9a9;
  font-weight: 500;
}
/* Blog List */
.blogList-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}
@media (max-width: 768px) {
  .blogList-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .blogList-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}
/* Blog Item */
.blogItem-wrap {
  display: flex;
  flex-direction: column;
}
.blogItem-cover {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 0.5rem;
}
.blogItem-wrap h3 {
  margin: 0.5rem 0 1rem 0;
  flex: 1;
}
.blogItem-desc {
  position: relative;
  max-height: 50px;
  overflow: hidden;
  padding-right: 0.6rem;
  font-size: 0.8rem;
  color: #a9a9a9;
}
.blogItem-desc::before {
  position: absolute;
  content: '...';
  bottom: 0;
  right: 0;
}
.blogItem-wrap footer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
}
.blogItem-link {
  text-decoration: none;
  color: inherit;
}
.blogItem-author {
  display: flex;
  align-items: center;
}
.blogItem-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}
.blogItem-author p {
  font-size: 0.6rem;
  color: #a9a9a9;
  font-weight: 600;
}
/* Empty List */
.emptyList-wrap {
  display: flex;
  justify-content: center;
}
.emptyList-wrap img {
  max-width: 250px;
  width: 100%;
}

/* Create content form */
.create-form{
  position: absolute;
  height: 90vh;
  top: 0;
  z-index: 3;
  backdrop-filter: blur(5px);
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formcont{
  background: #fff;
  width: 80%;
  display: flex;
  padding: 10px 10px;
  flex-direction: column;
  height: 80%;
  justify-content: center;
}
.formcont textarea{
  border: 1px solid #ccc;
  outline: none;
  resize: none;
  padding: 10px;
  font-size: 0.7rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  width: 100%;
  height: 12em;
}
.formcont span{
  display: flex;
}
.file{
  margin: 7px!important;
  padding: 0 !important;
}
